<template>
    <defaultSec class="flex-wrap" :title="'朋友圈'" :isDisableScroll="true" :isFlexSection="true">
        <div class="flex-row-wrap">
            <el-scrollbar class="flex-row-left">
                <div class="left-tab" @click="tabChange('fromCompany')" :class="formParam.createFrom=='fromCompany'?'left-tab-on':''">统一创建</div>
                <div class="left-tab" @click="tabChange('fromQW')" :class="formParam.createFrom=='fromQW'?'left-tab-on':''">客服创建</div>
            </el-scrollbar>
            <el-scrollbar v-show="formParam.createFrom=='fromCompany'" class="flex-row-right">
                <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                    <el-form-item label="任务名称">
                        <el-input v-model="formParam.taskName" placeholder="请输入任务名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="内容类型">
                        <el-select v-model="formParam.mediaType" filterable clearable
                                   placeholder="内容类型">
                            <el-option v-for="item in mediaTypeList"
                                       :key="item.value"
                                       :label="item.name"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="initList">查询</el-button>
                        <el-button type="success" @click="toDetail(0)">新建</el-button>
                    </el-form-item>
                </el-form>
                <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                    <el-table :data="dataList" border size="mini" v-loading="loading">
                        <el-table-column type="index" label="序号" width="50"></el-table-column>
                        <el-table-column label="任务名称" prop="taskName"></el-table-column>
                        <el-table-column label="内容" prop="mediaType"></el-table-column>
                        <el-table-column label="文案内容" prop="momentText"></el-table-column>
                        <el-table-column label="指定客服" prop="senderUser"></el-table-column>
                        <el-table-column label="指定用户标签" prop="customerTag"></el-table-column>
                        <!--                        <el-table-column label="点赞数" prop="" ></el-table-column>-->
                        <!--                        <el-table-column label="评论数" prop="" ></el-table-column>-->
                        <el-table-column label="任务状态" prop="taskStatus" width="90">
                            <template slot-scope="scope">
                                <el-tag size="mini" v-if="scope.row.taskStatus == 0" type="warning">未发布</el-tag>
                                <el-tag size="mini" v-if="scope.row.taskStatus == 3" type="success">已发布</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" prop="taskCreateTime" width="230"></el-table-column>

                        <el-table-column label="操作" fixed="right" width="160">
                            <template slot-scope="scope">
                                <!--                                <el-button-group>-->
                                <el-button type="primary" plain size="mini" @click="toDetail(scope.row.id)">详情</el-button>
                                <el-button type="danger" plain size="mini" v-if="scope.row.taskStatus == 0" @click="toPublish(scope.row.id)">发布</el-button>
                                <el-button type="danger" plain size="mini" v-if="scope.row.taskStatus == 3" @click="toCancle(scope.row.id)">撤回</el-button>
                                <!--                                </el-button-group>-->
                            </template>
                        </el-table-column>
                    </el-table>
                </tablePagination>
            </el-scrollbar>
            <el-scrollbar class="flex-row-right" v-show="formParam.createFrom=='fromQW'">
                <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                    <el-table :data="dataList" border size="mini" v-loading="loading">
                        <el-table-column type="index" label="序号" width="50"></el-table-column>
                        <el-table-column label="创建人" prop="CREATOR" width="120"></el-table-column>
                        <el-table-column label="可见状态" prop="VISIBLE_TYPE" width="120">
                            <template slot-scope="scope">
                                <el-tag type="success" size="mini" v-if="scope.row.VISIBLE_TYPE==1">公开</el-tag>
                                <el-tag type="danger" size="mini" v-if="scope.row.VISIBLE_TYPE==0">部分可见</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" prop="CREATE_TIME" width="180"></el-table-column>
                        <el-table-column label="文案内容" prop="TEXT_CONTENT"></el-table-column>
                        <el-table-column label="点赞数" prop="" width="150"></el-table-column>
                        <el-table-column label="评论数" prop="" width="150"></el-table-column>
                    </el-table>
                </tablePagination>
            </el-scrollbar>
        </div>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
export default {
    data() {
        return {
            activeName:'',
            formParam: {
                pageSize: 20,
                currentPage: 1,
                createFrom: 'fromCompany',
                taskName: '',
                mediaType: ''
            },
            dataList: [],
            mediaTypeList: [{value:'image',name:'图片'},{value:'video',name:'视频'},{value:'link',name:'链接'},{value:'text',name:'纯文字'}],
            showDialog: false,
            loading: false,
            total: 0
        }
    },
    components: {
        defaultSec, tablePagination
    },
    mounted(){
        this.initList()
    },
    methods: {
        //标签切换
        tabChange(index){
            this.formParam.createFrom = index
            this.initList()
        },
        initList() {
            this.loading = true;
            this.dataList = []
            this.$apiGet('lechun-cms/moment/momentList', this.formParam).then(res => {
                console.log(res)
                this.total = res.total;
                this.dataList = res.list
                this.loading = false;
            })
        },
        dataChange(){
            this.formParam.currentPage = 1;
            this.initList();
        },
        handlePage(page){
            this.formParam.currentPage = page;
            this.initList();
        },
        toDetail(id){
            console.log('id=' + id);
            this.$router.push({
                name: 'momentDetail',
                params:{id}
            })
        },
        toPublish(id){
            this.$apiGet('lechun-cms/moment/publishMomentJob', {'id':id}).then(res => {
                this.$message({
                    showClose: true,
                    message: '成功',
                    type: 'success'
                });
            })
        },
        toCancle(id){
            this.$apiGet('lechun-cms/moment/cancelMomentTask', {'id':id}).then(res => {
                this.$message({
                    showClose: true,
                    message: '成功',
                    type: 'success'
                });
            })
        }
    }
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.flex-row-wrap{
    display: flex;
    height: 100%;
    .flex-row-left{
        width: 100px;
        border-radius: 5px 0 0 5px;
        height: calc(100% - 20px);
        padding: 10px 0;
        background: #f5f5f5;
        font-size: 12px;
        box-shadow: 1px 0 6px 0 rgba(0,0,0,.26);
        position: relative;
        z-index: 9;
    }

    .flex-row-right{
        flex: 1;
        height: calc(100% - 20px);
        background: #ffffff;
        border-radius: 0 5px 5px 0;
        padding: 10px 10px 10px 15px;
    }
    .flex-column{
        display: flex;
        flex-direction: column;
    }
}
.flex-wrap{
    .left-tab{
        position: relative;
        font-size: 14px;
        padding: 10px 0;
        text-align: center;
        cursor: pointer;

    }
    .left-tab-on{
        font-size: 16px;
        position: relative;
        color: #209e91;
        background: rgba(32,158,145,.1);
        font-weight: bolder;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 3px;
            height: 100%;
            background: #209e91;
        }
    }
}
.expand-title {
    width: 70px;
    color: #444548;
    line-height: 30px;
    text-align: right;
    margin-right: 10px;
    font-weight: 600;
}

.expand-flex {
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
</style>